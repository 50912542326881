import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Form,
  Table,
} from "reactstrap";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FaSpinner } from "react-icons/fa";
import { ImSpinner8 } from "react-icons/im";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import { UploadChatBotFiles } from "store/actions/chatBotFilesAction";

const CustomerChatbot = () => {
  const loader = useSelector((state) => state.chatBotFiles.loading);

  const dispatch = useDispatch();
  const { id } = useParams();

  const [files, setFiles] = useState([]);
  const fileRef = useRef(null);
  const [dataFiles, setDataFiles] = useState([]);

  const handleFiles = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const validFileTypes = ["application/pdf"];
    const invalidFiles = selectedFiles.filter(
      (file) => !validFileTypes.includes(file.type)
    );
    if (invalidFiles.length > 0) {
      document.getElementById("file").value = "";
      toast.error("Please select a valid document type (pdf).");
      return;
    }
    setFiles([...files, ...selectedFiles]);
  };

  const removeFile = (index) => {
    let temp = [...files];
    temp.splice(index, 1);
    setFiles(temp);
  };
  const Submit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    let payload = {
      type: "Customer",
    };
    files.forEach((item) => {
      formData.append("files", item);
    });
    // formData.append("file", formData);
    dispatch(
      UploadChatBotFiles(payload, formData, () => {
        setFiles([]);
        document.getElementById("file").value = "";
      })
    );
  };

  return (
    <Container className="mt-4 mb-1">
      <Row>
        <Col>
          <div className="my-4">Customer Chatbot Data</div>
        </Col>
      </Row>
      <Form onSubmit={Submit}>
        <Row>
          <Col xs="6" className="d-flex justify-content-between">
            <input
              hidden
              ref={fileRef}
              type="file"
              id="file"
              onChange={handleFiles}
              required
            ></input>

            <Button
              className="cursor-pointer"
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                fileRef.current.click();
              }}
            >
              Upload
            </Button>
          </Col>
        </Row>

        <Row className=" my-4">
          <Col xs="12">
            <Table className="align-items-center table-flush" responsive>
              <thead
                className="thead-ligh bg-gradient-info text-white"
                style={{
                  fontSize: "24px",
                  fontWeight: "400",
                }}
              >
                <tr>
                  <th scope="col">Selected Files</th>

                  <th scope="col">Action</th>
                </tr>
              </thead>

              <tbody>
                <>
                  {files?.map((obj, index) => (
                    <>
                      <tr>
                        <td>{obj?.name}</td>

                        <td>
                          <span>
                            <button
                              disabled={loader}
                              className="icon-button  text-danger cursor-pointer"
                              onClick={() => {
                                removeFile(index);
                              }}
                            >
                              <MdDelete
                                style={{
                                  fontSize: "24px",
                                  fonrWeight: "400",
                                }}
                              />
                            </button>
                            {/* <MdDelete
                              onClick={() => {
                                removeFile(index);
                              }}
                              className="text-danger cursor-pointer"
                              style={{
                                fontSize: "24px",
                                fonrWeight: "400",
                              }}
                            /> */}
                          </span>
                        </td>
                      </tr>
                    </>
                  ))}
                  {files?.length == 0 && (
                    <>
                      <tr>
                        <td colspan="2">
                          {" "}
                          <div className="mt-2  d-flex align-items-center py-4 justify-content-center w-full">
                            <div>No Selected File</div>
                          </div>
                        </td>
                      </tr>
                    </>
                  )}
                </>
              </tbody>
            </Table>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col>
            <Button disabled={loader} type="submit" color="primary">
              Save {loader && <ImSpinner8 className="spinning-icon" />}
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default CustomerChatbot;
