import React, { useState } from "react";
import {
  Container,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Form,
} from "reactstrap";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FaSpinner } from "react-icons/fa";
import { ImSpinner8 } from "react-icons/im";
import { OpenApiKeyAction } from "store/actions/settingAction";

const WebURL = () => {
  const loader = useSelector((state) => state.setting.loading);

  const dispatch = useDispatch();
  const { id } = useParams();
  console.log("url itemId", id);

  const [url, setUrl] = useState("");

  const handleUrlChange = (event) => {
    const newUrl = event.target.value;

    setUrl(newUrl);
  };

  const urlHandler = (e) => {
    e.preventDefault();
    let payload = {
      NewOpenAIKey: url,
    };
    console.log(payload);
    dispatch(
      OpenApiKeyAction(payload, () => {
        setUrl("");
      })
    );
  };

  return (
    <Container className="my-4">
      <Form onSubmit={urlHandler}>
        <Row>
          <Col>
            <FormGroup>
              <Label for="urlInput">Enter Open Api Key:</Label>
              <Input
                type="text"
                placeholder="api key"
                value={url}
                onChange={handleUrlChange}
                name="url"
                id="urlInput"
                required
              />
              {/* {!isValidUrl && (
                <small style={{ color: "red" }}>Invalid URL</small>
              )} */}
            </FormGroup>
            <Button type="submit" disabled={loader} color="primary">
              Save {loader && <ImSpinner8 className="spinning-icon" />}
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default WebURL;
