import { useEffect, useState } from "react";

// reactstrap components

import classnames from "classnames";

import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ListGroup,
  ListGroupItem,
  Form,
} from "reactstrap";
// core components
import OnlyHeader from "components/Headers/OnlyHeader";
import chatbotlogo from "../assets/img/chatbot.png";
import { useSelector, useDispatch } from "react-redux";
import { Nav, NavItem, NavLink } from "reactstrap";
import Select from "react-select";
import WebURL from "../components/WebURL";

import classNames from "classnames";
import { withRouter } from "react-router-dom";
import PasswordUpdate from "components/PasswordUpdate";
import ChatBotSetting from "components/ChatBotSetting";

const Setting = () => {
  // const { itemId } = match;

  const [currentActiveTab, setCurrentActiveTab] = useState(1);
  const toggle = (tab) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };
  return (
    <>
      <OnlyHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="bg-transparent"></CardHeader>
              <CardBody>
                <div
                  style={{
                    display: "block",
                    padding: 30,
                  }}
                  className="setting-tab"
                >
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: currentActiveTab === 1,
                        })}
                        onClick={() => {
                          toggle(1);
                        }}
                      >
                        Update Password
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classNames({
                          active: currentActiveTab === 2,
                        })}
                        onClick={() => {
                          toggle(2);
                        }}
                      >
                        Open Api Key
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: currentActiveTab === 3,
                        })}
                        onClick={() => {
                          toggle(3);
                        }}
                      >
                        Chat Bot
                      </NavLink>
                    </NavItem>
                    
                  </Nav>
                  {currentActiveTab == "1" && (
                    <>
                      <PasswordUpdate />
                    </>
                  )}
                  {currentActiveTab == "2" && (
                    <>
                      <WebURL />
                    </>
                  )}
                  {currentActiveTab == "3" && (
                    <>
                      <ChatBotSetting />
                    </>
                  )}
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default withRouter(Setting);
