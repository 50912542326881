import Repository from "./Repository";
const UPLOAD_CHATBOT_FILES = "/Chat/File-Upload";
const VISTOR_FILES = "/Chatbot/CUSTOMER_FILES";
const GET_USER_FILES = "/Chat/Get-Files-By-Type";
const DELETE_FILES_ON_USERTYPE = "/ChatDelete-File";
const DOWNLOAD_FILE="/Chat/Download-File";

export default {
  UploadChatFiles(payload, formData) {
    return Repository.post(
      `${UPLOAD_CHATBOT_FILES}?type=${payload.type}`,
      formData
    );
  },

  GetFilesOnUserType(payload) {
    return Repository.get(`${GET_USER_FILES}?type=${payload}`);
  },

  DeleteFilesOnUserType(payload) {
    return Repository.delete(
      `${DELETE_FILES_ON_USERTYPE}?type=${payload.type}&file_id=${payload.file_id}`
    );
  },
  DownloadFile(file_path) {
    return Repository.get(
      `${DOWNLOAD_FILE}?file_path=${file_path}`
    );
  },
};
