import Repository from "./Repository";
const SIGNUP = "User/SignUp";
const LOGIN = "/User/Login";
const VERIFY = "user/approve-email?OTP=";
// const JOIN = "/waitList/Submit-Form";
const JOIN = "WaitList/Submit-Form";
const UPDATE_PASSWORD = "/User/Change-Password";
const ADD_CUSTOMIZATION = "/User/Add-Customization";
const UPDATE_CUSTOMIZATION = "/User/Update-Customization";
const GET_CUSTOMIZATION = "/User/Get-Customization";
export default {
    UpdatePasswordRep(user,payload) {
        return Repository.put(
            `${UPDATE_PASSWORD}?Email=${user.Email}`,payload);
    },
    signuprep(payload) {
        console.log("payload Hit")
        return Repository.post(`${SIGNUP}`, payload);
    },
    loginrep(payload) {
        return Repository.post(`${LOGIN}`, payload);
    },
    joinRep(payload) {
        return Repository.post(`${JOIN}`, payload);
    },
    verifyrep(payload) {
        return Repository.post(
            `${VERIFY}${payload.Otp}&Email=${payload.Email}`
        );
    },
    addCustomizationRep(payload) {
        return Repository.post(`${ADD_CUSTOMIZATION}`, payload);
    },
    updateCustomizationRep(payload) {
        return Repository.put(`${UPDATE_CUSTOMIZATION}`, payload);
    },
    getCustomizationRep(id) {
        return Repository.get(`${GET_CUSTOMIZATION}?UserId=${id}`);
    },
};
