import React, { useRef, useState } from "react";
import {
  Container,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Form,
} from "reactstrap";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FaSpinner } from "react-icons/fa";
import { ChatMessageAction } from "store/actions/settingAction";
import { toast } from "react-toastify";
import { ImSpinner8 } from "react-icons/im";

const ChatBotSetting = () => {
  const loader = useSelector((state) => state.userData.loading);
  const dispatch = useDispatch();
  //   const [dataBaseType, setDatabaseType] = useState();
  const [chatBotType, setChatBotType] = useState("");
  const [welcomeMessage, setWelcomeMessage] = useState("");
  // const [files, setFiles] = useState([]);
  // const [files, setFiles] = useState("");
  // const fileRef = useRef(null);
  // const [logo, setLogo] = useState("");
  // const handleFileChange = (e) => {
  //   const check = [".jpeg", ".png", ".gif", ".JPEG"];
  //   if (check.some((item) => e.target.files[0].name.endsWith(item))) {
  //     // const reader = new FileReader();
  //     // reader.onload = (event) => {
  //     //   setFiles(event.target.result);
  //     // };
  //     // reader.readAsDataURL(e.target.files[0]);
  //     setLogo(URL.createObjectURL(e.target.files[0]))
  //     setFiles(e.target.files[0]);
  //   } else {
  //     toast.warning("Please select only image.");
  //     setFiles("");
  //   }
  // };

  const handleSelectChange = (event) => {
    setChatBotType(event.target.value);
  };

  const Submit = (e) => {
    e.preventDefault();
    // const formData = new FormData();
    let payload = {
      Type: chatBotType,
      WelcomeMessage: welcomeMessage,
    };
    // formData.append("Logo", files);
    // console.log(payload, formData);
    dispatch(
      ChatMessageAction(payload, () => {
        setWelcomeMessage("");
        setChatBotType("");
        // setFiles("");
        // setLogo("")
      })
    );
  };
  return (
    <Container className="my-4">
      <Form onSubmit={Submit}>
        <Row>
          <Col lg={6} md={12}>
            <FormGroup>
              <Label for="databaseSelect">Select ChatBot:</Label>
              <Input
                type="select"
                value={chatBotType}
                onChange={handleSelectChange}
                required
              >
                <option value="">Select</option>
                <option value={"Visitor"}>Visitor</option>
                <option value={"Customer"}>Customer</option>
              </Input>
            </FormGroup>
          </Col>
          <Col lg={6} md={12}>
            <FormGroup>
              <Label>Welcome Message:</Label>
              <Input
                type="text"
                placeholder="welcome message"
                value={welcomeMessage}
                onChange={(e) => setWelcomeMessage(e.target.value)}
                required
              />
            </FormGroup>
          </Col>
          {/* <Col lg={6} md={12}>
            <FormGroup>
              <input
                hidden
                ref={fileRef}
                type="file"
                id="file"
                name="file"
                onChange={handleFileChange}
                required
              />
            </FormGroup>
            <Button
              className="cursor-pointer"
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                fileRef.current.click();
              }}
            >
              Upload Logo
            </Button>
            {logo.length > 0 && (
              <img
                className="img-fluid border rounded"
                style={{ maxWidth: "100%", maxHeight: "100px" }}
                // width={50}
                // height={50}
                multiple={true}
                src={logo}
                alt="Logo"
              />
            )}
          </Col> */}
        </Row>
        <Row className="mt-3">
          <Col className="d-flex justify-content-center">
            <Button disabled={loader} type="submit" color="primary">
              Save {loader && <ImSpinner8 className="spinning-icon" />}
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default ChatBotSetting;
