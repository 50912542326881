import {
  LOADER,
  GET_CHAT_BOT_CUSTOMIZE,
  UPDATE_CHATBOT_CUSTOMIZE,
  CHAT_CUSTOMIZE_LOADER,
  GET_CHAT_HISTORY,
} from "../types";
import { toast } from "react-toastify";

import { RepositoryFactory } from "../../repository/RepositoryFactory";
var chatbotRepo = RepositoryFactory.get("ChatBot");

export const setChatCustomizeLoading = (val) => async (dispatch) => {
  dispatch({ type: CHAT_CUSTOMIZE_LOADER, payload: val });
};

// Getting the default customization of ChatBot
export const GetChatBotCustomize = (type) => async (dispatch) => {
  console.log("get Type>>0e", type);
  await dispatch(setChatCustomizeLoading(true));
  try {
    let { data } = await chatbotRepo.getChatBotRepo(type);

    if (data?.status_code == 200) {
      dispatch({
        type: GET_CHAT_BOT_CUSTOMIZE,
        payload: data?.detail?.Cusomization,
      });
      await dispatch(setChatCustomizeLoading(false));
      // onSuccess();
    } else {
      toast.warning(data.detail);
      await dispatch(setChatCustomizeLoading(false));
    }
  } catch (e) {
    toast.error(e.message);
    await dispatch(setChatCustomizeLoading(false));
  }
};

// Updating the default customization of ChatBot
export const updateChatBotAction =
  (formData, onSuccess) => async (dispatch) => {
    await dispatch(setChatCustomizeLoading(true));
    try {
      let { data } = await chatbotRepo.customizeChatBotRepo(formData);
      if (data?.status_code == 200) {
        toast.success("Successfully Updated");
        dispatch({ type: UPDATE_CHATBOT_CUSTOMIZE });
        onSuccess();
        await dispatch(setChatCustomizeLoading(false));
      } else {
        toast.warning(data.detail);
        await dispatch(setChatCustomizeLoading(false));
      }
    } catch (e) {
      toast.error(e.message);
      await dispatch(setChatCustomizeLoading(false));
    }
  };

export const GetChatHistory = (type, page) => async (dispatch) => {
  console.log("typ0>>0e", type);
  await dispatch(setChatCustomizeLoading(true));
  try {
    let { data } = await chatbotRepo.getChatHistoryRepo(type, page);
    if (data?.status_code == 200) {
      dispatch({ type: GET_CHAT_HISTORY, payload: data?.detail });
      await dispatch(setChatCustomizeLoading(false));
      // onSuccess();
    } 
    else if (data?.status_code == 404) {
      toast.warning("No chat history saved");
      dispatch({ type: GET_CHAT_HISTORY, payload: [] });
      await dispatch(setChatCustomizeLoading(false));
      // onSuccess();
    }else {
      toast.warning(data.detail);
      await dispatch(setChatCustomizeLoading(false));
    }
  } catch (e) {
    toast.error(e.message);
    await dispatch(setChatCustomizeLoading(false));
  }
};
