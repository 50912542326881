import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { ImSpinner8 } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "components/Pagination";
import botLogo from "assets/img/Bot.png";
import chatAvator from "assets/img/chatAvator.jpg";
import { IoMdSend } from "react-icons/io";
import { IoPersonSharp } from "react-icons/io5";
import { GetChatHistory } from "store/actions/chatBotAction";

const History = (props) => {
  const getChatHistory = useSelector((state) => state.chatBot?.getChatHistory);
  const loading = useSelector((state) => state.chatBot?.loading);
  console.log("getChatHistory", getChatHistory?.ChatHistory?.length);

  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const itemsPerPage = 10;
  const totalPages = Math.ceil(getChatHistory?.TotalChatLength / itemsPerPage);
  console.log("totalPages", totalPages);

  useEffect(() => {
    dispatch(GetChatHistory(props?.type, page));
  }, [props?.type, page]);

  const handleUserClick = (userId) => {
    console.log("userId", userId);
    setSelectedUserId(userId);
  };
  useEffect(() => {
    setSelectedUserId(null);
    setPage(1);
  }, [props?.type]); // Reset selected user and page when type changes

  useEffect(() => {
    setSelectedUserId(null); // Reset selected user when page changes
  }, [page]);

  return (
    <Container className=" bg-white" fluid>
      {loading ? (
        <Row className="py-5">
          <Col lg={12} className="d-flex justify-content-center ">
            <ImSpinner8 size={50} className="spinning-icon" />
          </Col>
        </Row>
      ) : (
        <>
        {getChatHistory?.ChatHistory !==undefined ?(
        <>
          <Row className="d-flex justify-content-center mt-5">
            <Col lg={7} md={12}>
              <div>
                {getChatHistory?.ChatHistory &&
                  getChatHistory?.ChatHistory?.map((chat, index) => (
                    <div
                      onClick={() => handleUserClick(chat.data)}
                      className="d-flex align-items-center  cursor-pointer border p-1 rounded bg-info"
                      key={index}
                    >
                      <span className="mx-1 text-white">{index + 1}</span>
                      <img
                        className="rounded-circle mx-1"
                        src={chatAvator}
                        width={40}
                        height={40}
                        alt="chat avator"
                      />
                      <span style={{overflowWrap:"anywhere"}} className="mx-1">{chat.data[0]?.User}</span>
                    </div>
                  ))}
              </div>
            </Col>
            <Col lg={5} md={12} className="mt-2">
              <div className="d-flex flex-column align-items-center justify-content-center">
                <div
                  className="chatbot-screen  pt-3 formh form-Height"
                  style={{
                    borderTop: `10px solid #11cdef`,
                    borderBottom: `10px solid #11cdef`,
                    borderLeft: `1px solid #11cdef`,
                    borderRight: `1px solid #11cdef`,
                  }}
                >
                  <div className="d-flex align-items-center justify-content-center mb-2">
                    <img src={botLogo} width={50} height={50} />
                  </div>
                  <div className="chatbox scrollbar">
                    {selectedUserId?.map((chat, index) => (
                      <>
                        <div
                          className="d-flex justify-content-end my-2"
                          key={index}
                        >
                          <div className="UserMessages ml-2">
                            <div className=" shadow-sm py-2 ">{chat.User}</div>
                          </div>
                          <img
                            className=" m-n2"
                            src={chatAvator}
                            width={20}
                            height={20}
                          />
                        </div>

                        <div className="d-flex justify-content-start ">
                          <img
                            className=" m-n2"
                            src={botLogo}
                            width={20}
                            height={20}
                          />
                          <div className="AIMessages">
                            <div className="  shadow-sm py-2">{chat.AI}</div>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                  <div id="inputDiv" className="flex rounded-pill-footer">
                    <input
                      disabled
                      type="text"
                      className="rounded-pill-input pl-3  py-3 "
                      placeholder="Message..."
                    />
                    <IoMdSend className="hover:cursor-pointer" size={28} />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-center mt-4">
              <Pagination
                page={page}
                setPage={(newPage) => setPage(newPage)}
                totalPages={totalPages}
              />
            </Col>
          </Row>
          </>
          ):(
            <Row >
              <Col className="d-flex justify-content-center align-items-center mt-5">
              <div>No chat history saved</div></Col>
            </Row>
          )
          }
        </>
      )}
    </Container>
  );
};
export default History;
