import Repository from "./Repository";
const OPEN_API_KEY = "/User/Change-OpenAIKey";
const CHATBOT_MESSAGE = "/Bot/Update-Bot-Welcome-Message";

export default {
  OpenApiKeyRepo(payload) {
    return Repository.put(`${OPEN_API_KEY}`, payload);
  },
  
  ChatBotMessageRepo(payload) {
    return Repository.put(`${CHATBOT_MESSAGE}?Type=${payload.Type}&WelcomeMessage=${payload.WelcomeMessage}`);
  },
};
