export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const CHATE_BOT_CREATE = "CHATE_BOT_CREATE";
export const AUTH_SET_LOADING = "AUTH_SET_LOADING";
export const AUTH_NOTIFICATION = "AUTH_NOTIFICATION";
export const LOADER = "LOADER";
export const CHAT_HISTORY = "CHAT_HISTORY";
export const SITES_DATA = "SITES_DATA";

// chatbot Types
export const GET_CHAT_BOT_CUSTOMIZE = "GET_CHAT_BOT_CUSTOMIZE";
export const GET_CHAT_HISTORY = "GET_CHAT_HISTORY";
export const UPDATE_CHATBOT_CUSTOMIZE = "UPDATE_CHATBOT_CUSTOMIZE";
export const CHAT_CUSTOMIZE_LOADER = "CHAT_CUSTOMIZE_LOADER";
//Setting Types
export const OPEN_API_KEY = "OPEN_API_KEY";
export const CHAT_MESSAGE = "CHAT_MESSAGE";
//ChatBOT DATA
export const FILES_ON_USERBASE = "CUSTOMER_FILES";
export const FILES_LOADER = "FILES_LOADER";
export const GET_USER_FILES = "GET_USER_FILES";
export const DELETE_USER_FILES = "DELETE_USER_FILES";
export const SHOW_FILE = "SHOW_FILE";
export const SHOW_VISITOR_FILE = "SHOW_VISITOR_FILE";
export const SHOW_CUSTOMER_FILE = "SHOW_CUSTOMER_FILE";
