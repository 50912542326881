// reactstrap components
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
} from "reactstrap";
import { FaSpinner } from "react-icons/fa";
import { ImSpinner8 } from "react-icons/im";
import { LoginAction, setAuthLoading } from "store/actions/authAction";
import { useState } from "react";
import { IoIosEye } from "react-icons/io";
import { IoIosEyeOff } from "react-icons/io";


const Login = () => {
  const loader = useSelector((state) => state.userData.loading);
  console.log("loader==>", loader);
  const dispatch = useDispatch();
  const history = useHistory();
  const [showpass, setShowPass] = useState("password");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSignIn = (e) => {
    e.preventDefault();
    let payload = {
      Email: email,
      Password: password,
    };
    // history.push("/admin/chatbot");
    // Dispatch signIn action with payload
    console.log("formData ==>", payload);
    // history.push("/admin/index");
    dispatch(
      LoginAction(payload, () => {
        console.log("login");
        history.push("/admin/chatbot");
      })
    );
  };


  const showHidePass = (e) => {
    e.preventDefault();
    if (showpass === "password") {
      setShowPass("text");
    } else {
      setShowPass("password");
    }
  };
  return (
    <>
      <Col lg="12" md="12" className="d-flex justify-content-center mt-4">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Sign in</small>
            </div>
            <Form role="form" onSubmit={handleSignIn}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    value={email}
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    autoComplete="new-email"
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type={showpass}
                    value={password}
                    required
                    onChange={(e) => setPassword(e.target.value)}
                    autoComplete="new-password"
                  />
                   <a
                    href="showHidePass"
                    className="pt-2 px-2 text-muted"
                    onClick={showHidePass}
                  >
                    {showpass === "password" ? <IoIosEyeOff /> : <IoIosEye/>}
                  </a>
                </InputGroup>
              </FormGroup>

              <div className="text-center">
                <Button
                  disabled={loader}
                  className=" my-4 bg-green text-white"
                  type="submit"
                >
                  Sign in {" "}
                  {loader && <ImSpinner8 className="spinning-icon" />}
                </Button>
              </div>
            </Form>
            {/* <div className="mt-3 text-center">
              <Link to="/auth/register">Need an account? SIGN UP</Link>
            </div> */}
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Login;
