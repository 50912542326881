// reactstrap components
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Row,
  Container,
  Label,
} from "reactstrap";
import { FaSpinner } from "react-icons/fa";
import { ImSpinner8 } from "react-icons/im";

import {
  LoginAction,
  setAuthLoading,
  UpdatePasswordAction,
} from "store/actions/authAction";
import { useState } from "react";

const PasswordUpdate = () => {
  const loader = useSelector((state) => state?.userData?.loading);
  const userAuth = useSelector((state) => state?.authUser?.user?.UserEmail);
  console.log("userEmail==>", userAuth);
  const dispatch = useDispatch();
  const history = useHistory();
  const [data, setData] = useState({
    Email: "",
    OldPassword: "",
    NewPassword: "",
  });
  const handleChange = (e) => {
    setData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleUpdatePassword = (e) => {
    e.preventDefault();
    let user = {
      Email: userAuth,
    };
    let payload = {
      OldPassword: data.OldPassword,
      NewPassword: data.NewPassword,
    };
    
    dispatch(
      UpdatePasswordAction(user, payload, () => {
        history.push("/auth/login");
      })
    );
  };
  return (
    <>
      <Container>
        <Form role="form" onSubmit={handleUpdatePassword}>
          <Row className="mt-4">
            <Col md="6">
              <FormGroup>
                <Label for="urlInput">Enter Current Password:</Label>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>

                  <Input
                    placeholder="Current Password"
                    type="password"
                    name="OldPassword"
                    value={data.OldPassword}
                    required
                    onChange={handleChange}
                    autoComplete="new-password"
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="urlInput">Enter New Password:</Label>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="New Password"
                    type="password"
                    name="NewPassword"
                    value={data.NewPassword}
                    required
                    onChange={handleChange}
                    autoComplete="new-password"
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="">
                <Button
                  disabled={loader}
                  className=" my-2  text-white"
                  color="primary"
                  type="submit"
                >
                  Update Password
                  {loader && <ImSpinner8 className="spinning-icon" />}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default PasswordUpdate;
