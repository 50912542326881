import ChattingRepository from "./ChattingRepository";
import authRepository from "./authRepository";
import CheckoutRepository from "./CheckoutRepository";
import ChatBotCustomizeRepository from "./ChatBotCustomizeRepository";
import settingRepository from "./settingRepository";
import chatBotFilesRepository from "./chatBotFilesRepository";
const repositories = {
  chatting: ChattingRepository,
  auth: authRepository,
  checkout: CheckoutRepository,
  ChatBot: ChatBotCustomizeRepository,
  setting: settingRepository,
  chatBotFiles:chatBotFilesRepository,
};
export const RepositoryFactory = {
  get: (name) => repositories[name],
};
