// Register.js
import React, { useState } from "react";
import { useSelector } from "react-redux";
// import { SignUpAction } from "store/actions/authAction";
import { useHistory } from "react-router-dom";
import { FaSpinner } from "react-icons/fa";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";

const Register = () => {
  const history = useHistory();
  const loader = useSelector((state) => state.userData.loading);
  // Local state to manage form fields
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userType, setUserType] = useState("");

  const handleSignup = (e) => {
    e.preventDefault();
    // let payload = {
    //   FirstName: firstName,
    //   LastName: lastName,
    //   Email: email,
    //   Password: password,
    //   UserType: userType,
    // };
    history.push("/auth/login")
    // Dispatch signup action with form data
    // dispatch(
    //   SignUpAction(payload, () => {
    //     history.push("/auth/login");
    //   })
    // );
  };
  return (
    <>
      <Col lg="12" md="12" className="d-flex justify-content-center mt-4">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Sign up</small>
            </div>
            <Form role="form" onSubmit={handleSignup}>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-hat-3" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="First Name"
                    type="text"
                    value={firstName}
                    required
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-hat-3" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Last Name"
                    type="text"
                    value={lastName}
                    required
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    value={email}
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    autoComplete="new-email"
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    value={password}
                    required
                    onChange={(e) => setPassword(e.target.value)}
                    autoComplete="new-password"
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-hat-3" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="User Type"
                    type="text"
                    name="userType"
                    required
                    value={userType}
                    onChange={(e) => setUserType(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button
                  disabled={loader}
                  type="submit"
                  className="mt-4"
                  color="primary"
                >
                  {loader && <FaSpinner />}
                  Create account
                </Button>
              </div>
            </Form>
            <div className="mt-3">
              <span>Already have account?</span>{" "}
              <Link to="/auth/login">LogIn</Link>
            </div>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Register;
