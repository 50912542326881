import { GET_CHAT_BOT_CUSTOMIZE, UPDATE_CHATBOT_CUSTOMIZE,CHAT_CUSTOMIZE_LOADER,GET_CHAT_HISTORY } from "../types";
const initialState = {
  getChatCustomize: [],
  getChatHistory:[],
  loading: false,
};

export default function chatBotReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CHAT_BOT_CUSTOMIZE:
      return {
        ...state,
        getChatCustomize: action.payload,
      };
    case UPDATE_CHATBOT_CUSTOMIZE:
      return {
        ...state,
      };
    case CHAT_CUSTOMIZE_LOADER:
      return {
        ...state,
        loading:action.payload,
      };
    case GET_CHAT_HISTORY:
      return {
        ...state,
        getChatHistory:action.payload,

      };
    default:
      return { ...state };
  }
}
