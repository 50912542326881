// chrome.exe --user-data-dir="C://Chrome dev session" --disable-web-security
import axios from "axios";
//const baseDomain = "https://jw189vxk2xejeq-80.proxy.runpod.net/"
// const baseDomain = "https://backend.opentruth.ai/";
//const baseDomain = "https://backend.opentruth.ai/";
export const baseDomain = "https://backend.yourecourse.online/";
// export const baseDomain = "http://192.168.18.106:8080/";
// export const baseDomain = "http://127.0.0.1:8080";
 const baseURL = `${baseDomain}`;
// const getToken = () => {
//     let ls = JSON.parse(localStorage.getItem("authUser"));
//     console.log("ls", ls?.user?.AccessToken);
//     if (ls && ls?.user?.AccessToken != null) {
//         return ls?.user?.AccessToken;
//     } else {
//         return "";
//     }
// };

let axiosObj;
axiosObj = axios.create({
    baseURL,
    // headers: {
    //     token: getToken(),
    // },
});
axiosObj.interceptors.request.use(
    (config) => {
        // Dynamically set the token for each request
        const ls = JSON.parse(localStorage.getItem("authUser"));
        const token = ls?.token || "";
        config.headers.token = token;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
export default axiosObj;
