// Root Reducer

import { combineReducers } from "redux";
import authUserReducer from "./authUser";
import authReducer from "./authReducer";
import chatBotReducer from "./chatBotReducer";
import settingReducer from "./settingReducer";
import chatBotFilesReducer from "./chatBotFilesReducer";
export let rootReducer = combineReducers({
  authUser: authUserReducer,
  userData: authReducer,
  chatBot:chatBotReducer,
  setting:settingReducer,
  chatBotFiles:chatBotFilesReducer,
});

export default rootReducer;
