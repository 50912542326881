import {
    LOADER,
  CHAT_MESSAGE,
  OPEN_API_KEY
  } from "../types";
  import { toast } from "react-toastify";
  
  import { RepositoryFactory } from "../../repository/RepositoryFactory";
  var settingRepo = RepositoryFactory.get("setting");
  
  export const setLoading = (val) => async (dispatch) => {
    dispatch({ type: LOADER, payload: val });
  };
  
  // OPEN API KEY POSTING
  export const OpenApiKeyAction = (payload, onSuccess) => async (dispatch) => {
    await dispatch(setLoading(true));
    try {
      let { data } = await settingRepo.OpenApiKeyRepo(payload);
      if (data?.status_code == 200) {
        dispatch({ type: OPEN_API_KEY });
        toast.success(data.detail);
        onSuccess();
        await dispatch(setLoading(false));
      } else {
        toast.warning(data.detail);
        await dispatch(setLoading(false));
      }
    } catch (e) {
      toast.error(e.message);
      await dispatch(setLoading(false));
    }
  };
  
  
  // Sending the Message on USER TYPE
  export const ChatMessageAction = (payload, onSuccess) => async (dispatch) => {
    await dispatch(setLoading(true));
    try {
      let { data } = await settingRepo.ChatBotMessageRepo(payload);
      if (data?.status_code == 200) {
        toast.success("Successfully Updated");
        dispatch({ type: CHAT_MESSAGE });
        onSuccess();
        await dispatch(setLoading(false));
      } else {
        toast.warning(data.detail);
        await dispatch(setLoading(false));
      }
    } catch (e) {
      toast.error(e.message);
      await dispatch(setLoading(false));
    }
  };
  
  