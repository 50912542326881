import {
  DELETE_USER_FILES,
  FILES_LOADER,
  FILES_ON_USERBASE,
  GET_USER_FILES,
  SHOW_FILE,
  SHOW_VISITOR_FILE,
  SHOW_CUSTOMER_FILE,
} from "../types";
import { toast } from "react-toastify";
import { RepositoryFactory } from "../../repository/RepositoryFactory";
var ChatbotFiles = RepositoryFactory.get("chatBotFiles");

export const setFilesLoading = (val) => async (dispatch) => {
  dispatch({ type: FILES_LOADER, payload: val });
};

// Customer and Visitor Files Uploading
export const UploadChatBotFiles =
  (payload, formData, onSuccess) => async (dispatch) => {
    await dispatch(setFilesLoading(true));
    try {
      let { data } = await ChatbotFiles.UploadChatFiles(payload, formData);
      if (data?.status_code == 200) {
        dispatch({ type: FILES_ON_USERBASE });
        toast.success(data.detail);
        onSuccess();
        await dispatch(setFilesLoading(false));
      } else if (data?.status_code == 206) {
        dispatch({ type: FILES_ON_USERBASE });
        toast.warning("Uploaded Files " + data?.detail?.Uploaded + " Skipped Files " + data?.detail?.Skipped);
        onSuccess();
        await dispatch(setFilesLoading(false));
      } else {
        toast.warning(data.detail);
        await dispatch(setFilesLoading(false));
      }
    } catch (e) {
      toast.error(e.message);
      await dispatch(setFilesLoading(false));
    }
  };

export const GetUserFiles = (payload) => async (dispatch) => {
  await dispatch(setFilesLoading(true));
  try {
    let { data } = await ChatbotFiles.GetFilesOnUserType(payload);
    if (data?.status_code == 200 && data?.detail?.length > 0) {
      if (payload === "visitor") {
        dispatch({ type: SHOW_VISITOR_FILE, payload: data.detail });
        await dispatch(setFilesLoading(false));
      } else if (payload === "customer") {
        dispatch({ type: SHOW_CUSTOMER_FILE, payload: data.detail });
        await dispatch(setFilesLoading(false));
      }
      // toast.success("Successfully Saved");
      // onSuccess();
    } else {
      dispatch({ type: SHOW_VISITOR_FILE, payload: [] });
      dispatch({ type: SHOW_CUSTOMER_FILE, payload: [] });
      toast.warning(data.detail);
      await dispatch(setFilesLoading(false));
    }
  } catch (e) {
    toast.error(e.message);
    await dispatch(setFilesLoading(false));
  }
};

// Delete Files on User Type like Visitor or Customer
export const DeleteUserFilesOnUserType =
  (payload, onSuccess) => async (dispatch) => {
    await dispatch(setFilesLoading(true));
    try {
      let { data } = await ChatbotFiles.DeleteFilesOnUserType(payload);
      if (data?.status_code == 200) {
        toast.success("Successfully File Deleted");
        dispatch({ type: DELETE_USER_FILES });
        onSuccess();
        await dispatch(setFilesLoading(false));
      } else {
        toast.warning(data.detail);
        await dispatch(setFilesLoading(false));
      }
    } catch (e) {
      toast.error(e.message);
      await dispatch(setFilesLoading(false));
    }
  };

export const DownloadFiles = (file_path) => async (dispatch) => {
  await dispatch(setFilesLoading(true));
  try {
    let { data } = await ChatbotFiles.DownloadFile(file_path);
    if (data?.status_code == 200) {
      // toast.success("Successfully File Deleted");
      dispatch({ type: SHOW_FILE });
      // onSuccess();
      await dispatch(setFilesLoading(false));
    } else {
      toast.warning(data.detail);
      await dispatch(setFilesLoading(false));
    }
  } catch (e) {
    toast.error(e.message);
    await dispatch(setFilesLoading(false));
  }
};
