import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import OnlyHeader from "components/Headers/OnlyHeader";
import { baseDomain } from "repository/Repository";
import { FaSpinner } from "react-icons/fa";
import { Container, Col, Row, Table, Button, Spinner } from "reactstrap";
import DeleteModal from "components/DeleteModal";
import {
  DeleteUserFilesOnUserType,
  DownloadFiles,
  GetUserFiles,
} from "store/actions/chatBotFilesAction";

const VisitorFiles = () => {
  const { getUserFils, loading } = useSelector((state) => state.chatBotFiles);
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    dispatch(GetUserFiles("visitor"));
  }, []);

  const DeleteHandler = (e, item, index) => {
    e.preventDefault();
    setOpenModal(true);
    setData(item);
    // let arr = [...getUserFils];
    // arr[index].spiner = true;
    // let payload = {
    //   type: "visitor",
    //   file_id: item.Id,
    // };
    // ConfirmDelete(e,item,index)

    // dispatch(
    //   DeleteUserFilesOnUserType(payload, () => {
    //     dispatch(GetUserFiles("visitor"));
    //     arr[index].spiner = false;
    //   })
    // );
  };

  const ConfirmDelete = (e) => {
    e.preventDefault();
    setOpenModal(true);

    let payload = {
      type: "visitor",
      file_id: data.Id,
      // file_id: item.Id,
    };
    console.log("payload", payload);
    dispatch(
      DeleteUserFilesOnUserType(payload, () => {
        dispatch(GetUserFiles("visitor"));
        // arr[index].spiner = false;
        setOpenModal(false);
      })
    );
  };
  const CloseModal = () => {
    setOpenModal(false);
  };
  return (
    <>
      <OnlyHeader />
      <Container className="mt--7" fluid>
        <Row></Row>
        <Row>
          <Col lg={12} className="d-flex justify-content-center">
            {getUserFils.length > 0 ? (
              <>
                <Table className="bg-white" bordered striped responsive>
                  <thead
                    style={{ backgroundColor: "#2d8eef " }}
                    className="text-white"
                  >
                    <tr>
                      <th className="text-nowrap">File Name</th>
                      <th className="text-nowrap">Action</th>
                    </tr>
                  </thead>
                  {/* {!loading ? (  */}
                  <tbody>
                    {getUserFils?.map((item, index) => (
                      <tr key={index}>
                        <td className="text-nowrap">{item.FileName}</td>
                        <td>
                          <Button
                            // disabled={item.spiner}
                            type="button"
                            onClick={(e) => DeleteHandler(e, item, index)}
                          >
                            {/* {item.spiner === true && <FaSpinner />} */}
                            Delete
                          </Button>
                          <a
                            href={`${baseDomain}get-file?filename=${item.FilePath}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Button type="button">View File</Button>
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  {/* ) : (
                   <div className=" d-flex justify-content-center">
                     <Spinner />
                     <span>No data exsist against this user</span>
                   </div>
                 )} */}
                </Table>
              </>
            ) : (
              <div className="d-flex justify-content-center mt-5 pt-4">
                <span className="text-dark mt-5">
                  No file against this user
                </span>
              </div>
            )}
          </Col>
        </Row>
        {openModal && (
          <DeleteModal
            closeModal={CloseModal}
            data={data}
            DeleteHandler={ConfirmDelete}
            loading={loading}
          />
        )}
      </Container>
    </>
  );
};
export default VisitorFiles;
