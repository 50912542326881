import { useEffect, useState } from "react";
// reactstrap components
import classnames from "classnames";
import { Card, CardBody, Container, Row, Col } from "reactstrap";
import OnlyHeader from "components/Headers/OnlyHeader";
import { Nav, NavItem, NavLink } from "reactstrap";

import classNames from "classnames";
import { withRouter } from "react-router-dom";
import ChatbotCustomize from "components/chatbotCustomization";

const ChatBot = () => {

  const [currentActiveTab, setCurrentActiveTab] = useState(1);
  const toggle = (tab) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };
  return (
    <>
      <OnlyHeader />
      <Container className="mt--7 bg-white" fluid>
        <Row>
          <Col lg={12} className="flex justify-center">
            <Card className="shadow">
              <CardBody>
                <div
                  style={{
                    display: "block",
                    padding: 30,
                  }}
                  className="setting-tab "
                >
                  <Nav tabs className="d-flex justify-content-center">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: currentActiveTab === 1,
                        })}
                        onClick={() => {
                          toggle(1);
                        }}
                      >
                        Customer Chatbot
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classNames({
                          active: currentActiveTab === 2,
                        })}
                        onClick={() => {
                          toggle(2);
                        }}
                      >
                        Visitor Chatbot
                      </NavLink>
                    </NavItem>
                  </Nav>
                  {currentActiveTab == "1" && (
                    <>
                      <ChatbotCustomize type="customer" />
                    </>
                  )}
                  {currentActiveTab == "2" && (
                    <>
                      <ChatbotCustomize type="visitor" />
                    </>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default withRouter(ChatBot);
