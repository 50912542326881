import { CHAT_MESSAGE,LOADER,OPEN_API_KEY } from "../types";
const initialState = {
  loading: false,
};

export default function settingReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_API_KEY:
      return {
        ...state,
      };
    case CHAT_MESSAGE:
      return {
        ...state,
      };
    case LOADER:
      return {
        ...state,
        loading:action.payload
      };
    default:
      return { ...state };
  }
}
