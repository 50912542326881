// reactstrap components
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Col,
} from "reactstrap";
import { FaSpinner } from "react-icons/fa";

import {UpdatePasswordAction } from "store/actions/authAction";
import { useState } from "react";

const UpdatePassword = () => {

    const loader = useSelector((state) => state.userData.loading);
    console.log("loader==>", loader);
    const dispatch = useDispatch();
    const history = useHistory();
    // const [Email, setEmail] = useState("");
    // const [OldPassword, setOldPassword] = useState("");
    // const [NewPassword, setNewPassword] = useState("");
    const [data,setData]=useState({
        Email:"",
        OldPassword:"",
        NewPassword:"",
    })
    const handleChange=(e)=>{

        setData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value
          }));
          

    }

    const handleUpdatePassword = (e) => {
        e.preventDefault();
        let payload = {
            Email: data.Email,
            OldPassword: data.OldPassword,
            NewPassword: data.NewPassword,
        };
        // Dispatch signIn action with payload
        console.log("formData ==>", payload);
        dispatch(
            UpdatePasswordAction(payload, () => {
                history.push("/auth/login");
            })
        );
    };
    return (
        <>
            <Col lg="12" md="12" className="d-flex justify-content-center mt-4">
                <Card className="bg-secondary shadow border-0">
                    <CardBody className="px-lg-5 py-lg-5">
                        <div className="text-center text-muted mb-4">
                            <small>Change Password</small>
                        </div>
                        <Form role="form" onSubmit={handleUpdatePassword}>
                            <FormGroup className="mb-3">
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-email-83" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        placeholder="Email"
                                        name="Email"
                                        type="email"
                                        value={data.Email}
                                        required
                                        onChange={handleChange}
                                        autoComplete="new-email"
                                    />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-lock-circle-open" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        placeholder="Current Password"
                                        type="password"
                                        name="OldPassword"
                                        value={data.OldPassword}
                                        required
                                        onChange={handleChange}
                                        autoComplete="new-password"
                                    />
                                </InputGroup>
                            </FormGroup>

                            <FormGroup>
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-lock-circle-open" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        placeholder="New Password"
                                        type="password"
                                        name="NewPassword"
                                        value={data.NewPassword}
                                        required
                                        onChange={handleChange}
                                        autoComplete="new-password"
                                    />
                                </InputGroup>
                            </FormGroup>

                            <div className="text-center">
                                <Button
                                    disabled={loader}
                                    className=" my-4 bg-green text-white"
                                    type="submit"
                                >
                                    {loader && <FaSpinner />}
                                    Update Password
                                </Button>
                            </div>
                        </Form>
                        <div className="mt-3 text-center">
                            <Link to="/auth/register">Need an account? SIGN UP</Link>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </>
    );
};

export default UpdatePassword;
