import Repository from "./Repository";
const CHATTING = "Chat/Start-Chat";
const HISTORY_CHAT = "Chat/Get-Chat-History/";
const CHAT_DETAIL = "/Chat/Get-Chat-History-By-Id/";
const EXTRA_TOOLS = "/ExtraTools/User-Extra-Tool";
const CHECK_USER = "/User/Get-User-By-Id?UserId=";
const CHAT_HISTORY_DELETE = "/Chat/Delete-Chat-History?chatId=";
const SITES_DATA = "/User/Get-Site-Filter?UserId=";
const SAVE_SITES_DATA = "/User/Update-Site-Filter";
export default {
    // startChattingRep(payload,token) {
    //     const headers = {
    //         token: token,
    //     };

    //     return Repository.post(`${CHATTING}`, payload, { headers });
    // },

    deleteChatHistory(id) {
        return Repository.delete(`${CHAT_HISTORY_DELETE}${id}`);
    },
    checkUserRep(id) {
        return Repository.get(`${CHECK_USER}${id}`);
    },
    startChattingRep(payload, token) {
        const headers = {
            token: token,
        };
        return Repository.post(`${CHATTING}`, payload);
    },
    historyChatRep(email) {
        return Repository.get(`${HISTORY_CHAT}${email}`);
    },
    chatDetailRep(id) {
        return Repository.get(`${CHAT_DETAIL}${id}`);
    },
    getSitesRep(id) {
        return Repository.get(`${SITES_DATA}${id}`);
    },
    addSitesRep(payload) {
        return Repository.put(`${SAVE_SITES_DATA}`, payload);
    },
};
