import {useState } from "react";

// reactstrap components

import classnames from "classnames";

import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
} from "reactstrap";
// core components
import OnlyHeader from "components/Headers/OnlyHeader";
import { Nav, NavItem, NavLink } from "reactstrap";
import Chathistory from "components/chatHistory";
import classNames from "classnames";
import { withRouter } from "react-router-dom";


const ChatHistory = () => {

  const [currentActiveTab, setCurrentActiveTab] = useState(1);
  const toggle = (tab) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };
  return (
    <>
      <OnlyHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="bg-transparent"></CardHeader>
              <CardBody>
                <div
                  style={{
                    display: "block",
                    padding: 30,
                  }}
                  className="setting-tab"
                >
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: currentActiveTab === 1,
                        })}
                        onClick={() => {
                          toggle(1);
                        }}
                      >
                        Customer History
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classNames({
                          active: currentActiveTab === 2,
                        })}
                        onClick={() => {
                          toggle(2);
                        }}
                      >
                        Visitor History
                      </NavLink>
                    </NavItem>
                    
                  </Nav>
                  {currentActiveTab == "1" && (
                    <>
                      <Chathistory type="customer"/>
                    </>
                  )}
                  {currentActiveTab == "2" && (
                    <>
                      <Chathistory type="visitor"/>
                    </>
                  )}
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default withRouter(ChatHistory);
