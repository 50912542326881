import React from "react";
import { Button, ButtonGroup } from "reactstrap";

const Pagination = ({ page, setPage, totalPages }) => {
    const lastPage = totalPages;
    const pagesToShow = 4;
    const generatePages = () => {
        const pages = [];
        if (lastPage <= pagesToShow) {
            for (let i = 1; i <= lastPage; i++) {
                pages.push(i);
            }
        } else {
            if (page <= pagesToShow - 1) {
                for (let i = 1; i <= pagesToShow; i++) {
                    pages.push(i);
                }
            } else if (page >= lastPage - pagesToShow + 1) {
                for (let i = lastPage - pagesToShow + 1; i <= lastPage; i++) {
                    pages.push(i);
                }
            } else {
                for (let i = page - 1; i <= page + 1; i++) {
                    pages.push(i);
                }
            }
            if (pages[0] !== 1) {
                pages.unshift("...");
            }
            if (pages[pages.length - 1] !== lastPage) {
                pages.push("...");
            }
        }
        return pages;
    };

    return (
        <div>
            <ButtonGroup>
                <Button
                    onClick={() => {
                        if (page > 1) {
                            setPage(page - 1);
                        }
                    }}
                    disabled={page === 1}
                    color="primary"
                >
                    {"<"}
                </Button>

                {generatePages()?.map((pageNumber, index) => (
                    <Button
                        key={index}
                        onClick={() => {
                            pageNumber !== "..." && setPage(pageNumber);
                        }}
                        color={page === pageNumber ? "primary" : "secondary"}
                    >
                        {pageNumber}
                    </Button>
                ))}

                <Button
                    onClick={() => {
                        if (page < lastPage) {
                            setPage(page + 1);
                        }
                    }}
                    disabled={page === lastPage}
                    color="primary"
                >
                    {">"}
                </Button>
            </ButtonGroup>
        </div>
    );
};

export default Pagination;
