import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import OnlyHeader from "components/Headers/OnlyHeader";
import { Container, Col, Row, Table, Button, Spinner } from "reactstrap";
// import { GetUserFiles } from "store/actions/chatBotFilesAction";
// import { DeleteUserFilesOnUserType } from "store/actions/chatBotFilesAction";
import DeleteModal from "components/DeleteModal";
import { Link } from "react-router-dom";
import {
  DeleteUserFilesOnUserType,
  ShowFiles,
  GetUserFiles,
} from "store/actions/chatBotFilesAction";

import { baseDomain } from "repository/Repository";
import { FaSpinner } from "react-icons/fa";

const CustomerFiles = () => {
  const { getCustomerFiles, loading } = useSelector(
    (state) => state.chatBotFiles
  );
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    dispatch(GetUserFiles("customer"));
  }, []);

  const DeleteHandler = (e, item, index) => {
    e.preventDefault();
    setOpenModal(true);
    setData(item);
    // let arr = [...getCustomerFiles];
    // arr[index].spiner = true;
  };
  const ConfirmDelete = (e) => {
    e.preventDefault();
    setOpenModal(true);

    let payload = {
      type: "customer",
      file_id: data.Id,
      // file_id: item.Id,
    };
    console.log("payload", payload);
    dispatch(
      DeleteUserFilesOnUserType(payload, () => {
        dispatch(GetUserFiles("customer"));
        // arr[index].spiner = false;
        setOpenModal(false);
      })
    );
  };
  const CloseModal = () => {
    setOpenModal(false);
  };
  return (
    <>
      <OnlyHeader />
      <Container className="mt--7" fluid>
        <Row>
          <Col sm={12} md={12} lg={12}>
            {/* {loading ? (
              <div className="d-flex justify-content-center">
                <span className="text-white">Loading...</span>
              </div>
            ) : ( */}
            <>
              {getCustomerFiles.length > 0 ? (
                <Table className="bg-white" bordered striped responsive>
                  <thead
                    style={{ backgroundColor: "#2d8eef " }}
                    className="text-white"
                  >
                    <tr>
                      <th className="text-nowrap">File Name</th>
                      <th className="text-nowrap">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {getCustomerFiles?.map((item, index) => (
                      <tr key={index}>
                        <td className="text-nowrap">{item.FileName}</td>
                        <td>
                          <Button
                            // disabled={item.spiner}
                            type="button"
                            onClick={(e) => DeleteHandler(e, item, index)}
                          >
                            {/* {item.spiner === true && <FaSpinner />} */}
                            Delete
                          </Button>
                          <a
                            href={`${baseDomain}get-file?filename=${item.FilePath}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Button type="button">View File</Button>
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <div className="d-flex justify-content-center mt-5 pt-4">
                  <span className="text-dark mt-5">
                    No file against this user
                  </span>
                </div>
              )}
            </>
            {/* )} */}
          </Col>
        </Row>
        {openModal && (
          <DeleteModal
            data={data}
            DeleteHandler={ConfirmDelete}
            loading={loading}
            closeModal={CloseModal}
          />
        )}
      </Container>
    </>
  );
};
export default CustomerFiles;
