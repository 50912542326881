import {
  FILES_ON_USERBASE,
  FILES_LOADER,
  GET_USER_FILES,
  DELETE_USER_FILES,
  SHOW_FILE,
  SHOW_CUSTOMER_FILE,
  SHOW_VISITOR_FILE,
} from "../types";
const initialState = {
  loading: false,
  getUserFils: [],
  getCustomerFiles:[]
};

export default function chatBotFilesReducer(state = initialState, action) {
  switch (action.type) {
    case FILES_ON_USERBASE:
      return {
        ...state,
      };
    case FILES_LOADER:
      return {
        ...state,
        loading: action.payload,
      };
    case SHOW_VISITOR_FILE:
      return {
        ...state,
        getUserFils: action.payload,
      };
    case SHOW_CUSTOMER_FILE:
      return {
        ...state,
        getCustomerFiles: action.payload,
      };
    case SHOW_FILE:
      return {
        ...state,
        // getUserFils: action.payload,
      };
    case DELETE_USER_FILES:
      return {
        ...state,
      };
    default:
      return { ...state };
  }
}
