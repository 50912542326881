import { LOADER, LOGIN, LOGOUT } from "../types";
import { toast } from "react-toastify";

import { RepositoryFactory } from "../../repository/RepositoryFactory";
var auth = RepositoryFactory.get("auth");

export const setAuthLoading = (val) => async (dispatch) => {
  dispatch({ type: LOADER, payload: val });
};

export const SignUpAction = (payload, onSuccess) => async (dispatch) => {
  console.log("payload", payload);
  await dispatch(setAuthLoading(true));
  try {
    let { data } = await auth.signuprep(payload);
    if (data?.status_code == 200) {
      toast.success("Successfully Sign Up");
      onSuccess();
      await dispatch(setAuthLoading(false));
    } else {
      toast.warning(data.detail);
      await dispatch(setAuthLoading(false));
    }
  } catch (e) {
    toast.error(e.message);
    await dispatch(setAuthLoading(false));
    dispatch(setAuthLoading(false));
  }
};
export const LoginAction = (payload, onSuccess) => async (dispatch) => {
  await dispatch(setAuthLoading(true));
  try {
    let { data } = await auth.loginrep(payload);
    console.log("data===>", data);
    if (data?.status_code === 200) {
      dispatch({ type: LOGIN, payload: data?.detail });
      toast.success("Successfully LogIn");
      onSuccess();
      await dispatch(setAuthLoading(false));
    } else {
      toast.warning(data?.detail);
      await dispatch(setAuthLoading(false));
    }
  } catch (e) {
    toast.error(e);
    await dispatch(setAuthLoading(false));
  }
};

//Update password

export const UpdatePasswordAction =(user, payload, onSuccess) => async (dispatch) => {
    console.log("Action Payload", payload);
    await dispatch(setAuthLoading(true));
    try {
      let { data } = await auth.UpdatePasswordRep(user, payload);
      console.log(data);
      if (data?.status_code === 200) {
        onSuccess();
        toast.success(data?.detail);
        await dispatch(setAuthLoading(false));
      } else {
        toast.error(data?.detail);
        await dispatch(setAuthLoading(false));
      }
      // if (data?.Detail == "User Password Updated Successfully") {
      //   onSuccess();
      //   toast.success(data?.Detail);
      //   await dispatch(setAuthLoading(false));
      // } else {
      //   toast.error(data?.Detail?.Detail);
      //   await dispatch(setAuthLoading(false));
      // }
    } catch (e) {
      toast.error(e);
      await dispatch(setAuthLoading(false));
    }
  };

export const JoinAction = (payload, onSuccess) => async (dispatch) => {
  console.log("payload", payload);
  await dispatch(setAuthLoading(true));
  try {
    let { data } = await auth.joinRep(payload);
    console.log("data", data);

    if (data?.Detail) {
      toast.success(data?.Detail);
      onSuccess();
      dispatch(setAuthLoading(false));
    } else {
      toast.error(data?.detail);
      dispatch(setAuthLoading(false));
    }
    // if (data?.user_id) {
    //     dispatch({ type: LOGIN, payload: data });
    //     onSuccess();
    //     await dispatch(setAuthLoading(false));
    // } else {
    //     toast.error(data?.detail);
    //     await dispatch(setAuthLoading(false));
    // }
  } catch (e) {
    toast.error(e.message);

    dispatch(setAuthLoading(false));
  }
};
export const VerifyAction = (payload, onSuccess) => async (dispatch) => {
  console.log("payload", payload);
  await dispatch(setAuthLoading(true));
  try {
    let { data } = await auth.verifyrep(payload);
    toast.success(data.detail);
    await dispatch(setAuthLoading(false));
    if (data?.user_id) {
      dispatch({ type: LOGIN, payload: data });
      onSuccess();
    } else {
      toast.error(data?.detail);
      await dispatch(setAuthLoading(false));
    }
  } catch (e) {
    toast.error(e.message);
    await dispatch(setAuthLoading(false));
    dispatch(setAuthLoading(false));
  }
};

export const addCustomizationAction =
  (payload, onSuccess) => async (dispatch) => {
    await dispatch(setAuthLoading(true));
    try {
      let { data } = await auth.addCustomizationRep(payload);
      console.log("post data", data);
      if (data?.status_code == 200) {
        onSuccess();
        toast.success("customize successfully");
        await dispatch(setAuthLoading(false));
      } else {
        toast.warn(data?.detail);
        await dispatch(setAuthLoading(false));
      }
    } catch (e) {
      toast.error(e);
      await dispatch(setAuthLoading(false));
    }
  };

export const updateCustomizationAction =
  (payload, onSuccess) => async (dispatch) => {
    await dispatch(setAuthLoading(true));
    try {
      let { data } = await auth.updateCustomizationRep(payload);

      console.log("post data", data);
      if (data?.status_code == 200) {
        onSuccess();
        toast.success("settings saved!");
        await dispatch(setAuthLoading(false));
      } else {
        toast.warn(data?.detail);
        await dispatch(setAuthLoading(false));
      }
    } catch (e) {
      toast.error(e);
      await dispatch(setAuthLoading(false));
    }
  };

export const getCustomizationAction = (id) => async (dispatch) => {
  try {
    let { data } = await auth.getCustomizationRep(id);

    if (data?.detail?.ChatbotColor) {
      dispatch({ type: "CUSTOMIZE", payload: data?.detail });
    } else {
    }
  } catch (e) {
    toast.error(e);
  }
};

export const login = () => async (dispatch) => {
  dispatch({
    type: LOGIN,
    payload: { id: 123, first_name: "abc", last_name: "def" },
  });
};

export const logout = (onSuccess) => async (dispatch) => {
  localStorage.clear();
  onSuccess();
  dispatch({
    type: LOGOUT,
  });
};

// Update password
