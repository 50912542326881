import Index from "views/Index.js";
import Setting from "views/Setting.js";
import Login from "views/auth/Login.js";
import ChatBot from "views/Chatbot.js";
import Package from "views/Package";
import Register from "views/auth/Register";
import UpdatePassword from "views/auth/UpdatePassword";
import DataChatBot from "views/DataChatBot";
import VisitorFiles from "views/VisitorFiles";
import CustomerFiles from "views/CustomerFiles";
import chatHistory from "views/chatHistory";
var routes = [
  // {
  //   path: "/index",
  //   name: "Dashboard",
  //   icon: "fas fa-home text-primary",
  //   component: Index,
  //   layout: "/admin",
  // },
  {
    path: "/chatbot",
    name: "Chatbot Customization",
    icon: "fas fa-robot text-green",
    component: ChatBot,
    layout: "/admin",
  },

  {
    path: "/chatbotBot_data",
    name: "Chatbot Data",
    icon: "fas fa-robot text-green",
    component: DataChatBot,
    layout: "/admin",
  },

  // {
  //   path: "/packages",
  //   name: "Packages",
  //   icon: "fas fa-dollar-sign text-black",
  //   component: Package,
  //   layout: "/admin",
  // },

  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
    isMenu: false,
  },
  {
    path: "/updatepassword",
    name: "Update Password",
    icon: "ni ni-key-25 text-info",
    component: UpdatePassword,
    layout: "/auth",
    isMenu: false,
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-key-25 text-info",
    component: Register,
    layout: "/auth",
    isMenu: false,
  },
  {
    path: "/setting",
    name: "Setting",
    icon: "fas fa-cog text-red",
    component: Setting,
    layout: "/admin",
  },
  {
    path: "/visitorFiles",
    name: "Visitor Files",
    icon: "fas fa-user text-info",
    component: VisitorFiles,
    layout: "/admin",
  },
  {
    path: "/customerFiles",
    name: "Customer Files",
    icon: "fas fa-user text-info",
    component:CustomerFiles,
    layout: "/admin",
  },
  {
    path: "/chatHistory",
    name: "ChatHistory",
    icon: "fas fa-user text-info",
    component:chatHistory,
    layout: "/admin",
  },
];
export default routes;
