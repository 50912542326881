import Repository from "./Repository";
const CUSTOMIZE_CHATBOT = "/Customization/Update-Customization";
// const GET_CHAT_BOT_CUSTOMIZE = "/Customization/Show-All-Customization";
const GET_CHAT_BOT_CUSTOMIZE = "/Customization/Get-Customization-By-Bot-Type";
const GET_CHAT_HISTORY = "/Chat/Show-Chat-History";

export default {
  customizeChatBotRepo(formData) {
    return Repository.put(`${CUSTOMIZE_CHATBOT}`,formData);
  },
  getChatBotRepo(type) {
    console.log("get Type in Repo",type)
    return Repository.get(`${GET_CHAT_BOT_CUSTOMIZE}?Type=${type}`);
  },
  getChatHistoryRepo(type,page) {
     console.log("repo>>>",type)

    return Repository.get(`${GET_CHAT_HISTORY}?Page=${page}&Type=${type}`);
  },
};
