import { useEffect, useRef, useState } from "react";
import {
  Container,
  Row,
  Col,
  Label,
  Input,
  Button,
  FormGroup,
} from "reactstrap";
import OnlyHeader from "components/Headers/OnlyHeader";
import { useSelector, useDispatch } from "react-redux";
import { IoMdSend } from "react-icons/io";
import { ImSpinner8 } from "react-icons/im";
import { updateChatBotAction } from "store/actions/chatBotAction";
import { GetChatBotCustomize } from "store/actions/chatBotAction";
import { toast } from "react-toastify";
import { baseDomain } from "repository/Repository";
import dummyLogo from "../assets/img/dummyLogo.jpeg";
import botLogo from "../assets/img/Bot.png";
import { FaArrowLeft } from "react-icons/fa";
const Icons = (props) => {
  const dispatch = useDispatch();
  const { getChatCustomize, loading } = useSelector((state) => state.chatBot);
  const [data, setData] = useState({
    BotBackgroundColor: "#000000",
    BotTextColor: "#FFFFFF",
    ChatbotBackgroundColor: "#FFFFFF",
    ChatbotBorderColor: "#000000",
    UserBackgroundColor: "#808080",
    UserTextColor: "#000000",
    ChatbotTextColor: "#FFFFFF",
  });
  const [files, setFiles] = useState("");
  const fileRef = useRef(null);
  const [chatLog, setChatLogo] = useState("");

  const handleFileChange = (e) => {
    const check = [".jpeg", ".png", ".gif", ".JPEG",".jpg"];
    if (check.some((item) => e.target.files[0].name.endsWith(item))) {
      setChatLogo(URL.createObjectURL(e.target.files[0]));
      setFiles(e.target.files[0]);
    } else {
      toast.warning("Please select only image.");
      setFiles("");
    }
  };
console.log("props",props.type)
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
    if (getChatCustomize?.Id && getChatCustomize !== undefined) {
      setData({ ...getChatCustomize });
    }
  }, [getChatCustomize]);


  useEffect(() => {
      dispatch(GetChatBotCustomize(props?.type));
  }, []);

  const updateCustomizationHandler = (e) => {
    e.preventDefault();
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }

    formData.append("file", files);
    formData.append("BotType", props?.type);
    dispatch(
      updateChatBotAction(formData, () => {
        dispatch(GetChatBotCustomize(props?.type));
        setChatLogo("");
      })
    );
  };
  return (
    <>
      {/* <OnlyHeader /> */}
      <Container className=" bg-white" fluid>
        {loading ? (
          <Row className="py-5">
            <Col lg={12} className="d-flex justify-content-center ">
              <ImSpinner8 size={50} className="spinning-icon" />
            </Col>
          </Row>
        ) : (
          <>
            <Row className="d-flex align-items-center justify-content-center mt-5">
              <Col lg={7} md={12}>
                <Row>
                  <Col lg={6} md={6} sm={12} className="mt-2">
                    <Label className="m-0">ChatBot Background Color</Label>
                    <Input
                      type="color"
                      value={data?.ChatbotBackgroundColor}
                      name="ChatbotBackgroundColor"
                      onChange={handleChange}
                      className="p-0 border-0 rounded-pillow   my-1"
                    />
                  </Col>
                  <Col lg={6} md={6} sm={12} className="mt-2">
                    <Label className="m-0">Chatbot Border Color</Label>
                    <Input
                      type="color"
                      value={data?.ChatbotBorderColor}
                      name="ChatbotBorderColor"
                      onChange={handleChange}
                      className="p-0 border-0 rounded-pillow  my-1"
                    />
                  </Col>
                  <Col lg={6} md={6} sm={12} className="mt-2">
                    <Label className="m-0">Response Text Background</Label>
                    <Input
                      type="color"
                      value={data?.BotBackgroundColor}
                      name="BotBackgroundColor"
                      onChange={handleChange}
                      className="p-0 border-0 rounded-pillow my-1"
                    />
                  </Col>
                  <Col lg={6} md={6} sm={12} className="mt-2">
                    <Label className="m-0">Response Text Color</Label>
                    <Input
                      type="color"
                      value={data?.BotTextColor}
                      name="BotTextColor"
                      onChange={handleChange}
                      className="p-0 border-0 rounded-pillow my-1"
                    />
                  </Col>
                  <Col lg={6} md={6} sm={12} className="mt-2">
                    <Label className="m-0">User Message Background</Label>
                    <Input
                      type="color"
                      value={data?.UserBackgroundColor}
                      onChange={handleChange}
                      name="UserBackgroundColor"
                      className="p-0 border-0 rounded-pillow my-1"
                    />
                  </Col>
                  <Col lg={6} md={6} sm={12} className="mt-2">
                    <Label className="m-0">User Message Text</Label>
                    <Input
                      type="color"
                      value={data?.UserTextColor}
                      name="UserTextColor"
                      onChange={handleChange}
                      className="p-0 border-0 rounded-pillow   my-1"
                    />
                  </Col>
                  <Col lg={6} md={6} sm={12} className="mt-2">
                    <Label className="m-0">Send Button Color</Label>
                    <Input
                      type="color"
                      value={data?.ChatbotTextColor}
                      name="ChatbotTextColor"
                      onChange={handleChange}
                      className="p-0 border-0 rounded-pillow my-1"
                    />
                  </Col>
                  <Col lg={6} md={6} sm={12} className="mt-2">
                    <FormGroup>
                      {/* <Label>Upload Logo:</Label><br></br> */}
                      <input
                        hidden
                        ref={fileRef}
                        type="file"
                        id="file"
                        name="file"
                        onChange={handleFileChange}
                        required
                      />
                    </FormGroup>
                    <Button
                      className="cursor-pointer mt-2"
                      color="primary"
                      onClick={(e) => {
                        e.preventDefault();
                        fileRef.current.click();
                      }}
                    >
                      Upload Logo
                    </Button>
                    {chatLog.length > 0 && (
                      <img
                        className="img-fluid border rounded"
                        width={50}
                        height={50}
                        multiple={true}
                        src={chatLog}
                        alt="Logo"
                      />
                    )}
                  </Col>
                </Row>
              </Col>
              <Col lg={5} md={12} className="mt-2">
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <div
                    className="chatbot-screen  pt-3 formh form-Height"
                    style={{
                      borderTop: `10px solid ${data?.ChatbotBorderColor}`,
                      borderBottom: `10px solid ${data?.ChatbotBorderColor}`,
                      borderLeft: `1px solid ${data?.ChatbotBorderColor}`,
                      borderRight: `1px solid ${data?.ChatbotBorderColor}`,
                      backgroundColor: `${data?.ChatbotBackgroundColor}`,
                    }}
                  >
                    <div className="d-flex align-items-center justify-content-center">
                      <img
                        src={
                          getChatCustomize?.LogoPath !== "" &&
                          getChatCustomize?.LogoPath !== undefined &&
                          getChatCustomize?.LogoPath !== null
                            ? `${baseDomain}get-file?filename=${getChatCustomize?.LogoPath}`
                            : props.type == "customer"
                            ? dummyLogo
                            : botLogo
                        }
                        width={60}
                        height={60}
                      />
                    </div>
                    <div className="chatbox">
                      <div className="d-flex">
                        <img
                          id="customerlogo"
                          src={
                            getChatCustomize?.LogoPath !== "" &&
                            getChatCustomize?.LogoPath !== undefined &&
                            getChatCustomize?.LogoPath !== null
                              ? `${baseDomain}get-file?filename=${getChatCustomize?.LogoPath}`
                              : props.type == "customer"
                              ? dummyLogo
                              : botLogo
                          }
                          className="rounded-circle mt-4 mr-1 "
                          height={25}
                          width={25}
                        />
                        <div className="message bot ml-n2">
                          <div
                            id="customerMsg"
                            className="bot-message shadow-sm py-2 "
                            style={{
                              backgroundColor: data?.BotBackgroundColor,
                              color: data?.BotTextColor,
                            }}
                          >
                            Welcome
                          </div>
                        </div>
                      </div>
                      <>
                        <div className="d-flex justify-content-end ">
                          <div className=" message user ml-3 mr-n2">
                            <div
                              id="visitorMsg"
                              className="user-message  py-2 shadow-sm "
                              style={{
                                backgroundColor: data?.UserBackgroundColor,
                                color: data?.UserTextColor,
                              }}
                            >
                              Welcome
                            </div>
                          </div>
                        </div>
                      </>
                    </div>

                    {/* )} */}
                    <div id="inputDiv" className="flex rounded-pill-footer">
                      <input
                        disabled
                        type="text"
                        className="rounded-pill-input pl-3  py-3 "
                        placeholder="Message..."
                      />
                      <IoMdSend
                        className="hover:cursor-pointer"
                        size={28}
                        style={{
                          color: data?.ChatbotTextColor,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="my-4">
              <Col className="d-flex justify-content-center">
                <button
                  disabled={loading}
                  className="btn btn-success"
                  type="button"
                  onClick={updateCustomizationHandler}
                >
                  Update {loading && <ImSpinner8 className="spinning-icon" />}
                </button>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </>
  );
};

export default Icons;
